.landing_img::before {
  content: "";
  position: absolute;
  width: 529.268px;
  height: 529.268px;
  z-index: -1;
  left: 0;
  border-radius: 529.268px;
  border: 1.782px solid #fff;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.52) 0%, rgba(255, 255, 255, 0) 100%);
}
nav .connect-btn button {
  border-radius: 12px;
  background: #dcd3fe;
}

@media only screen and (max-width: 1290px) {
  .landing_innerContainer {
    flex-direction: column;
  }

  .landing_info {
    width: 98%;
  }

  .tags {
    width: 100%;
  }

  .landing_heading {
    height: auto;
    width: 100%;
  }

  .stand_box {
    margin-bottom: 1rem;
  }

  .landing_img {
    height: auto;
    width: 90%;
    margin: 3rem auto;
  }
}

@media only screen and (max-width: 1150px) {
  .landing_heading {
    height: auto;
    width: auto;
    font-size: 30px;
  }

  .value_container p,
  .vision_container p {
    height: auto;
    width: auto;
  }

  .landing_innerContainer {
    width: 95%;
  }
}

@media only screen and (max-width: 900px) {
  .footer {
    border-top: none;
  }
  nav.active .connect-btn button {
    width: 30vw;
  }
  .nav.active .menus ul {
    margin-top: 1rem;
  }
  body::before {
    content: "";
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    left: 0;
    bottom: 0;

    background: linear-gradient(184deg, #f5f5f5 16.55%, rgba(245, 245, 245, 0) 83.96%),
      url(https://s3-alpha-sig.figma.com/img/f647/60af/9971e777aa0372d8fecdfcf50bbb2913?Expires=1707091200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=paKXIVshVyETM54Ek6qA0M8CHhNSftqGD4nPuMRe5yUH-r~fnw6UiUphIJ8bqTrxx5C0PcdJHtk3I~BJD4UKcj9mHK~kSXuJUbEt4kZ-fHv1V5TEqctu2d7Gvr2R7TqWbpVjDtQS4G1dKj96yOasMCDwsQoUBBy0JMtVhHAqudDKNoK49idBawEJpRJoo4Agn-6LpkmZfJLKtPH9Ryidd28tMWu7A9Oe4RbfFLlowXDlnL05Ms7AUlj4oa39DrI6c6z2IyYi-a6WkHuRR7xH4JmfUcuPGXoGg8h77SPY748sjooEnAlk06dHWs6SDNMA44B9iFRGDRfbkFK7ULZVsA__),
      lightgray -670.521px -101px / 443.857% 115.577% no-repeat;
    background-position: bottom;
  }
  nav.nav.active .logo {
    z-index: 99;
  }
  .nav.active .connect-btn {
    display: block;
    z-index: 99;
    position: fixed;
    top: 30rem;
  }

  .landing_img::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    z-index: -1;
    border-radius: 18.601px;
    border: 0.66px solid #fff;
  }

  .nav_wrapper {
    position: relative;
    padding: 1rem;
    display: flex;
    justify-content: start;
    width: 100%;
    background: #fff;
    border: 1px solid #c3b3b3;
  }

  .nav {
    backdrop-filter: none;
    background-color: transparent;
    padding: 0;
  }
}

@media only screen and (max-width: 500px) {
  .landing_heading {
    width: 342px;
    height: auto;
    font-size: 36px;
  }
}

@media only screen and (max-width: 900px) {
  .marquee .content h1 {
    font-size: 20px;
  }
  .footer .footer_menu {
    margin: 1rem 0;
  }
  nav .logo img {
    height: 35px;
    width: 50px;
  }
  nav .logo h1 {
    font-size: 20.808px;
  }
  .landing_img img {
    border-radius: 18px;
    box-shadow: -5.944px 1.321px 12.284px -8.585px #413f43;
  }
  .landing_img {
    border-radius: 650px;
    border-radius: 12px;
    width: 85vw;
  }

  .landing_innerContainer {
    flex-direction: column;
    width: 95%;
    margin: 5rem auto;
    margin-bottom: 1rem;
  }
  div#connect {
    padding-top: 0;
  }
  .marquee {
    height: 150px;
    margin-top: 4rem;
    transform: rotate(3.5deg);
  }
  .avl_banner {
    margin: 3rem 0;
    overflow: hidden;
  }
  .landing_heading {
    height: auto;
    font-size: 36px;
  }

  .avl_desc p {
    padding: 0 15px 0 16px;
  }

  .footer .decentral,
  .footer .email,
  .footer .newsletter {
    margin: 10px;
  }

  .value_container .value_links {
    flex-direction: column;

    width: auto;
  }

  .value_container .value_links a {
    font-size: 10px;
    padding: 5px 8px;
    min-height: 10px;
    margin: 5px;
    width: auto;
  }

  .track {
    background: linear-gradient(90deg, #121418 37.53%, #81797a 63.04%, #121418 76.77%);
  }

  .stand_box {
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  .value_container p {
    width: auto;
    height: auto;
    font-size: 18px;
  }

  .value_media {
    width: auto;
    height: auto;
  }

  .video-heading {
    flex-direction: column;
  }

  .value_media .light_line {
    width: 73vw;
    margin-bottom: 1rem;
  }

  .vision_container p,
  .avl_desc {
    height: auto;
    width: auto;
    font-size: 18px;
  }

  .value_container .pay_btns {
    flex-direction: column;
    width: auto;
  }

  .aval_btn {
    width: 90%;
  }

  .newsletter button {
    max-width: 300px;
    width: -webkit-fill-available;
  }

  .newsletter .email_box {
    max-width: 500px;
    margin: 0.5rem auto;
  }

  .tags {
    width: auto;
  }

  .cv_container .highlights .data {
    flex-direction: column;
  }

  .cv_container .info {
    font-size: 18px;
    width: 100%;
  }

  .cv_container .date {
    width: 100%;
  }

  .footer {
    flex-direction: column;
    width: auto;
  }

  .landing_info {
    width: 90%;
  }

  .Rupay img {
    object-fit: contain;
    border-radius: 20px;
  }

  .vision_container::before {
    height: 21rem;
  }

  .pay_btns .support {
    width: 100%;
    margin: 10px 0;
  }

  .vision_container {
    height: auto;
    padding: 3px;
  }

  .newsletter h1 {
    width: auto;
    margin-top: 10px;
    text-align: center;
  }

  .avl_banner h1 {
    margin: 0 1.5rem;
    font-size: 22px;
  }

  .value_container::before,
  .value_media .video img,
  #cv::before {
    height: auto;
  }

  .cv_container .highlights {
    width: auto;
  }
}

@media only screen and (max-width: 450px) {
  .vision_container::before {
    height: 29rem;
  }
}

nav.active .menus ul {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 2rem;
}

nav.active .menus ul li {
  display: flex;
  margin: 2rem 0;
  padding: 1rem;
  align-items: center;
}
nav.active .menus ul li a {
  width: fit-content;
}
nav.active .menus {
  display: block;
  position: absolute;
  top: 0;
  width: 80%;
  background: #fff;
  backdrop-filter: blur(19.25px);
  left: 0;
  height: 102vh;
  z-index: 1;
}

nav.active .menu-toggler span:nth-child(1) {
  transform: rotate(-45deg);
  top: 4px;
}

nav.active .menu-toggler span:nth-child(2) {
  opacity: 0;
}

nav.active .menu-toggler span:nth-child(3) {
  transform: rotate(45deg);
  bottom: 14px;
}

.menu-toggler {
  position: absolute;
  right: 2rem;
}

@media only screen and (max-width: 900px) {
  .animation_region_pc {
    display: none;
  }
  .animation_region_mobile {
    display: block;
  }

  nav .menus,
  .connect-btn {
    display: none;
  }

  nav {
    justify-content: center;
  }

  .nav.active .nav_wrapper {
    width: 100%;
  }

  .menu-toggler {
    display: block;
  }
}

.img_name {
  z-index: 0 !important;
}

.marquee {
  scale: 1.1;
}
.marquee_outer_wrapper {
  scale: 1.1;
  transform: rotate(3.5deg);
  width: 100%;
  overflow: hidden;
}

.marquee .avl_banner .content h1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.available_container .marquee .content {
  align-items: center;
}
.available_container .marquee .content h1 {
  margin: 0;
}

#root {
  overflow-x: hidden;
}
