@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Clash Display";
  src: url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570587/kara-fonts/ts2mbbtlvdejvcqvf4ei.woff2") format("woff2"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570587/kara-fonts/beenkrnngsrifadiezcp.woff") format("woff"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570587/kara-fonts/b663bunwvutpswct40ry.woff2") format("woff"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570587/kara-fonts/cszvi2ojjraq3k9v7rc1.woff") format("woff"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570587/kara-fonts/kft6rdftfi6nzqxmemny.woff") format("woff"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570586/kara-fonts/i5x6xqcnh19j3ray1fyi.woff2") format("woff2"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570587/kara-fonts/dfnainbs6idwklvrqil3.woff2") format("woff2"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570586/kara-fonts/xgvwxrwumph01f0l52zf.woff") format("woff"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570586/kara-fonts/py9gfjlgshbnzusbremg.woff") format("woff"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570586/kara-fonts/ofgpv1fbxbrfrpylxnz4.woff2") format("woff2"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570586/kara-fonts/vhta5vdcwplrfqpwlqvr.woff") format("woff"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570585/kara-fonts/xmfjtc7td8pbbg1xgn2s.woff2") format("woff2"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570585/kara-fonts/dxgq4bzzjpsnm0j0au8h.woff") format("woff"),
    url("https://res.cloudinary.com/rizwanamjad/raw/upload/v1712570585/kara-fonts/zpssggbsmikoutlwaf4w.woff") format("woff");
}
body {
  font-family: Clash Display, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.hero-tag {
  color: #181b1f;
  padding: 8px 12px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}
.mobile-hidden {
  display: block;
}
.mobile-shown {
  display: none;
}
@media screen and (max-width: 768px) {
  .mobile-width-full {
    width: 100% !important;
  }
  .mobile-gap-48 {
    gap: 3rem !important;
  }
  .mobile-y-padding-y-18 {
    padding-top: 1.125rem 0 !important;
    padding-bottom: 1.125rem 0 !important;
  }
  .mobile-padding-x-38 {
    padding-left: 2.375rem !important;
    padding-right: 2.375rem !important;
  }
  .mobile-padding-x-32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .mobile-padding-x-12 {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
  .mobile-col {
    flex-direction: column;
  }
  .mobile-min-height-380 {
    min-height: 380px !important;
  }
  .mobile-no-max-w {
    max-width: none !important;
  }
  .mobile-hidden {
    display: none !important;
  }
  .mobile-shown {
    display: block;
  }
  .mobile-x-margin-48 {
    margin: 0 3rem;
  }
  .mobile-x-padding-0 {
    padding: 0 !important;
  }
  .mobile-font-48 {
    font-size: 3rem !important;
  }
  .mobile-font-40 {
    font-size: 2.5rem !important;
  }
  .mobile-font-38 {
    font-size: 2.375rem !important;
  }
  .mobile-font-32 {
    font-size: 2rem !important;
  }
  .mobile-font-28 {
    font-size: 1.75rem !important;
  }
  .mobile-font-24 {
    font-size: 1.5rem !important;
  }
  .mobile-font-20 {
    font-size: 1.25rem !important;
  }
  .mobile-font-16 {
    font-size: 1rem !important;
  }
  .mobile-font-10 {
    font-size: 0.625rem !important;
  }
  .mobile-gap-72 {
    gap: 4.5rem !important;
  }
}
@media screen and (max-width: 425px) {
  .mobile-sm-padding-y-28 {
    padding: 1.75rem 0 !important;
  }
  .mobile-sm-padding-y-18 {
    padding-top: 1.125rem 0 !important;
    padding-bottom: 1.125rem 0 !important;
  }
  .mobile-sm-padding-x-32 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .mobile-sm-padding-x-16 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .mobile-sm-width-half {
    width: 50%;
  }
  .mobile-sm-min-height-unset {
    min-height: unset !important;
  }
  .mobile-sm-x-margin-20 {
    margin: 0 1.25rem;
  }
  .mobile-sm-font-24 {
    font-size: 1.5rem !important;
  }
  .mobile-sm-font-20 {
    font-size: 1.25rem !important;
  }
  .mobile-sm-font-18 {
    font-size: 1.125rem !important;
  }
  .mobile-sm-font-16 {
    font-size: 1rem !important;
  }
  .mobile-sm-font-14 {
    font-size: 0.875rem !important;
  }
  .mobile-sm-font-12 {
    font-size: 0.75rem !important;
  }
  .mobile-sm-font-10 {
    font-size: 0.625rem !important;
  }
  .mobile-sm-font-8 {
    font-size: 0.5rem !important;
  }
  .mobile-sm-font-6 {
    font-size: 0.375rem !important;
  }
}
