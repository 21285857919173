/* Scroller.css */ 
/* animtion of information for mobile size: */
@keyframes moveLeftRight {
 0% {
    transform: translateX(-100%);
  }
  
25%{
  transform: translateX(0);
}

100%{
  transform: translateX(-150%);
}

}

/* animtion of information for tablet size: */
@keyframes moveLeftRight1 {
 0% {
    transform: translateX(-100%);
  }
  
25%{
  transform: translateX(0);
}

100%{
  transform: translateX(-160%);
}

}

/* animtion of information for laptop size: */
@keyframes moveLeftRight2 {
 0% {
    transform: translateX(-100%);
  }
  
25%{
  transform: translateX(0);
}

100%{
  transform: translateX(-200%);
}

}

/* animtion of information for laptop size: */
@keyframes moveLeftRight3 {
 0% {
    transform: translateX(-100%);
  }
  
25%{
  transform: translateX(0);
}

100%{
  transform: translateX(-190%);
}

}

  

.left-right {
  animation: moveLeftRight 6s linear infinite;
}

@media screen and (min-width: 640px) {
  .left-right{
    animation: moveLeftRight1 6s linear infinite;
  }
}

@media screen and (min-width: 1024px) {
  .left-right{
    animation: moveLeftRight2 6s linear infinite;
    width: 100%;
  }
}

@media screen and (min-width: 1430px) {
  .left-right{
    animation: moveLeftRight3 6s linear infinite;
    width: 100%;
  }
}

