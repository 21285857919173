@keyframes moveRightLeft {
  0% {
    transform: translateX(0);
  }
  50%{
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.hover-animate {
  animation: moveRightLeft 7s linear 1;
}

.leave-animate {
  animation: moveLeftRight 4s linear 1;
}
