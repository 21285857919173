.error_container{
    height: 100vh;
    color: gray;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.error_container img{
    filter: opacity(0.5);
    width: 300px;
    height: 300px;
    object-fit: contain;

}
.error_container h1{
    font-size: 3rem;
    margin: 1rem;
}

.error_container h3{
    font-weight: 400;
    font-size: 2rem;
    margin: 1rem;
}

.error_container p{
    width: 32%;
    margin: 1rem auto;
}

@media only screen and (max-width: 900px) {

    .error_container p{
        width: 90%;
        margin: 1rem auto;
    }
    

}